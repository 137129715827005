<template>
    <div id="categories">
        <div class="top-wrapper">
            <div class="title mb-3">More Articles</div>

            <div class="buttons-wrapper">
                <img src="@/assets/images/icons/left-arrow.svg" alt="left arrow" @click="backClicked" />
                <img src="@/assets/images/icons/right-arrow.svg" alt="right arrow" @click="continueClicked" />
            </div>
        </div>
        <div class="carousel-wrapper">
            <carousel
                :navigationEnabled="true"
                :perPageCustom="[
                    [0, 1],
                    [400, 1.5],
                    [576, 2],
                    [992, 3],
                ]"
                :autoplay="true"
                :loop="true"
                ref="carousel"
                :autoplay-timeout="5000">
                <slide v-for="(post, index) in posts" :key="index">
                    <article-card :article="getArticle(post)"></article-card>
                </slide>
            </carousel>
        </div>
    </div>
</template>

<script>
import ArticleCard from "../../components/ArticleCard";
import { mapState } from "vuex";
import { Carousel, Slide } from "vue-carousel";

export default {
    name: "popular-posts",
    props: ["posts", "users",],
    components: {
        "article-card": ArticleCard,
        Carousel,
        Slide,
    },
    methods: {
        getArticle(article) {
            let author = this.users ? this.users.filter((user) => user.id === userID) : [{ name: "Willyou.net" }];
            article.author = author && author[0] ? author[0] : article.author;
            // if (this.latest === true) return article;

            let userID = article.author;
            let highestCategory = Math.max(...article.categories);
            let selectedCategory = this.articlesCategories
                ? this.articlesCategories.filter((generalCategory) => generalCategory.id === highestCategory)[0]
                : [];
            article.selectedCategory = selectedCategory;
            return article;
        },

        backClicked() {
            const el = document.querySelector(".VueCarousel-navigation-prev");
            if (el) el.click();
        },
        continueClicked() {
            const el = document.querySelector(".VueCarousel-navigation-next");
            if (el) el.click();
        },
    },
    computed: {
        ...mapState("willyou", ["articlesCategories"]),
    },
};
</script>

<style lang="scss" scoped>
#categories {
    width: 100%;

    .top-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 0.75rem;
        .buttons-wrapper {
            align-items: center;
            display: none;

            img {
                opacity: 0;
                cursor: pointer;
                margin: 0 0.5rem;
                height: 40px;
                @media (min-width: 600px) {
                    opacity: 1;
                }
            }

            @media (min-width: 600px) {
                display: flex;
            }
        }
    }

    .title {
        font-family: "Noto Serif SC", serif;
        font-size: 24px;
        font-weight: 200;
        color: rgba(0, 0, 0, 0.87);
    }

}
</style>

<style lang="scss">
.VueCarousel-navigation-next,
.VueCarousel-navigation-prev {
    opacity: 0;
    cursor: unset;
}

.VueCarousel-pagination {
    display: none;

    @media (min-width: 992px) {
        display: block;
    }
}
</style>
